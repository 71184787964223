import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [
      { path: '403', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', meta: { name: '系统首页' }, component: () => import('../views/manager/Home') },
      { path: 'user', meta: { name: '管理员信息' }, component: () => import('../views/manager/User') },
      { path: 'password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'project', meta: { name: '项目信息' }, component: () => import('../views/manager/Project') },
      { path: 'generate', meta: { name: '生成记录' }, component: () => import('../views/manager/Generate') },
      { path: 'download', meta: { name: '下载记录' }, component: () => import('../views/manager/Download') },
      { path: 'recharge', meta: { name: '充值记录' }, component: () => import('../views/manager/Recharge') },
      { path: 'exchange', meta: { name: '兑换记录' }, component: () => import('../views/manager/Exchange') },
      { path: 'logs', meta: { name: '系统日志' }, component: () => import('../views/manager/Logs') },
      { path: 'dict', meta: { name: '数据字典' }, component: () => import('../views/manager/Dict') },
      { path: 'notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      { path: 'feedback', meta: { name: '意见反馈' }, component: () => import('../views/manager/Feedback') },
      { path: 'template', meta: { name: '模板信息' }, component: () => import('../views/manager/Template') },
      { path: 'scoreExchange', meta: { name: '积分兑换' }, component: () => import('../views/manager/ScoreExchange') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '*', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
